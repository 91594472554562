import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import request from "../utils/request";
import { setAuthData, selectAuthdata } from "../slices/authSlice";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useLocation } from "react-router-dom";
import { myContext } from "../utils/contextApi/context";
// import { NotificationDropdown } from "../components/Modal";
// import { useRef } from "react";

function Header() {
  const authInfo = useSelector(selectAuthdata);
  const [userType, setUserType] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = React.useState(false);
  const location = useLocation();
  const {unReadCount,setUnReadCount} = useContext(myContext)
  // const [data, setData] = React.useState([]);
  // const [loading, setLoading] = React.useState(false);
  // const [error, setError] = React.useState(null);
  // const dropdownRef = useRef(null);
  // const iconRef = useRef(null);


  const logout = () => {
    localStorage.clear();
    navigate("/login", { replace: true });
    window.location.reload()
  };

  useEffect(() => {
    fetchLogedUserInfo();
  }, []);

  const fetchLogedUserInfo = () => {
    let userData = localStorage.getItem("userData");
    if (userData) {
      dispatch(setAuthData({ authData: JSON.parse(userData) }));
    }
  };
  const toggleSidebar = () => {
    const body = document.body;
    let classNameToAddOrRemove = "toggle-sidebar";
    if (body.classList.contains(classNameToAddOrRemove)) {
      // If it's present, remove the class
      body.classList.remove(classNameToAddOrRemove);
    } else {
      // If it's not present, add the class
      body.classList.add(classNameToAddOrRemove);
    }
  };


  // //function to open the NotificationDropdown
  //   const handleOpen = async () => {
  //     if (!modalOpen) {
  //       setModalOpen(true);
  //       await fetchData();
  //     } else {
  //       setModalOpen(false);
  //     }
  //   };


  //   // function to close the modal when the user clicks outside of it
  //   useEffect(() => {
  //     const handleClickOutside = (event) => {
  //       // If the click is outside both the dropdown and the icon, close the modal
  //       if (
  //         dropdownRef.current &&
  //         !dropdownRef.current.contains(event.target) &&
  //         iconRef.current &&
  //         !iconRef.current.contains(event.target)
  //       ) {
  //         setModalOpen(false);
  //       }
  //     };

  //     if (modalOpen) {
  //       document.addEventListener("mousedown", handleClickOutside);
  //     } else {
  //       document.removeEventListener("mousedown", handleClickOutside);
  //     }

  //     return () => {
  //       document.removeEventListener("mousedown", handleClickOutside);
  //     };
  //   }, [modalOpen]);


  //   //getting the userid from the local storage.
  //   const storedData = localStorage.getItem("userData");
  //   const parsedData = JSON.parse(storedData);
  //   const userID = parsedData.user.id;



  //   const fetchData = async () => {
  //     setLoading(true);
  //     setError(null);
  //     try {
  //       const response = await fetch(`http://103.66.79.27:18080/api/notifications/user/${userID}`);
  //       if (!response.ok) {
  //         throw new Error('Network response was not ok');
  //       }
  //       const result = await response.json();
  //       setData(result);
  //     } catch (error) {
  //       setError(error.message);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  // console.log("kooooiiii",data)


  return (
    <header id="header" className="header fixed-top d-flex align-items-center">
      <div className="d-flex align-items-center justify-content-between">
        <a href="/" className="logo d-flex align-items-center">
          <img style={{ width: "52px" }} src="/assets/img/logo.jpg" alt="" />
          {/* <span className="d-none d-lg-block">FGCoinAdmin</span> */}
        </a>
        {location.pathname !== "/notification" && ( // Hide when path is /navigation
          <i
            className="bi bi-list toggle-sidebar-btn"
            onClick={toggleSidebar}
          ></i>
        )}
      </div>
      {/* 
            <div className="search-bar">
                <form className="search-form d-flex align-items-center" method="POST" action="#">
                    <input type="text" name="query" placeholder="Search" title="Enter search keyword" />
                    <button type="submit" title="Search"><i className="bi bi-search"></i></button>
                </form>
            </div> */}

      <nav className="header-nav ms-auto">
        <ul className="d-flex align-items-center">
          <li className="nav-item d-block d-lg-none">
            <a className="nav-link nav-icon search-bar-toggle " href="#">
              <i className="bi bi-search"></i>
            </a>
          </li>
          <div>
            <li
              className="nav-item"

            >
              <a className="nav-link nav-icon notification" href="/admin/camera-detection">
                <div className="icon-container">
                  <NotificationsIcon />
                  <span className="notification-badge ">{unReadCount ? unReadCount > 99 ? "99+":unReadCount:0}</span> 
                </div>
              </a>
            </li>

          </div>

          <li className="nav-item dropdown pe-3">
            <a
              className="nav-link nav-profile d-flex align-items-center pe-0"
              href="#"
              data-bs-toggle="dropdown"
            >
              <img
                src="/assets/img/logo.jpg"
                alt="Profile"
                className="rounded-circle"
              />
              <span className="d-none d-md-block dropdown-toggle ps-2">
                {authInfo?.name}
              </span>
            </a>

            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
              <li className="dropdown-header">
                <h6>{authInfo?.user?.username}</h6>
                <span>{authInfo?.user?.email}</span>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              {userType == "admin" && (
                <>
                  <li>
                    <Link
                      to="/admin/update-profile"
                      className="dropdown-item d-flex align-items-center"
                    >
                      <i className="bi bi-person"></i>
                      <span>My Profile</span>
                    </Link>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <Link
                      className="dropdown-item d-flex align-items-center"
                      to="/admin/update-password"
                    >
                      <i className="bi bi-gear"></i>
                      <span>Update Password</span>
                    </Link>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                </>
              )}
              {userType == "user" && (
                <>
                  <li>
                    <Link
                      to="/user/update-profile"
                      className="dropdown-item d-flex align-items-center"
                    >
                      <i className="bi bi-person"></i>
                      <span>My Profile</span>
                    </Link>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>

                  <li>
                    <Link
                      className="dropdown-item d-flex align-items-center"
                      to="/user/update-password"
                    >
                      <i className="bi bi-gear"></i>
                      <span>Update Password</span>
                    </Link>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                </>
              )}

              <li>
                <hr className="dropdown-divider" />
              </li>

              <li>
                <a
                  className="dropdown-item d-flex align-items-center"
                  onClick={logout}
                >
                  <i className="bi bi-box-arrow-right"></i>
                  <span>Sign Out</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
