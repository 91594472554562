import { createContext, useState } from "react";

export const myContext = createContext();

export const MyContextProvider = ({ children }) => {
  const [unReadCount, setUnReadCount] = useState(0);
  const [notifications,setNotifications] = useState([])
  const [userData,setUserData] = useState()

  const contextValue = {
   unReadCount,
   setUnReadCount,
   notifications,
   setNotifications,
   userData,
   setUserData
  };

  // console.log("context value",contextValue)

  return (
    <myContext.Provider value={contextValue}>{children}</myContext.Provider>
  );
};
