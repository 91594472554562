

 const checkPermissions =(permissionkey)=>{
    let userDetails =JSON.parse(localStorage.getItem('userData'));
    let permissionList =[];
    if(userDetails.user.roles){
        let permissionListObj=userDetails.user.roles[0].privileges;
        permissionListObj.forEach(element => {
            permissionList.push(element.name)
        });
        if(!permissionList.includes(permissionkey)){
           return false;
        }else{
            return true;
        }
          
    }
}

export default checkPermissions