import React from 'react'

function Footer() {
  return (
    <footer id="footer" className="footer">
    <div className="copyright">
      &copy; Copyright <strong><span>Biotrail</span></strong>. All Rights Reserved
    </div>
   
  </footer>
  )
}

export default Footer