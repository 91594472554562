import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import checkPermissions from '../utils/helper';
import { useNavigate } from 'react-router-dom';

function VideoPlayBox({ handleClose, data }) {
    const navigate = useNavigate();
    if (!checkPermissions('detect-view-image-video')) {
        navigate('/permission-denied')
    }
    const [blobUrl, setBlobUrl] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        let userData1 = JSON.parse(localStorage.getItem('userData'));
        const fetchImage = async () => {
            try {

                const response = await axios.get(`${process.env.REACT_APP_API_URL}/detection/download/${data.videoFilename}`, { responseType: 'blob', headers: { Accept: 'video/mp4;charset=UTF-8', Authorization: userData1 ? 'Bearer ' + userData1.authToken : null } });


                const url = URL.createObjectURL(response.data);

                setBlobUrl(url);
            } catch (err) {

                setError('Failed to fetch image');
            } finally {
                setLoading(false);
            }
        };

        fetchImage();

        // Cleanup function to revoke the blob URL when the component unmounts
        return () => {
            if (blobUrl) {
                URL.revokeObjectURL(blobUrl);
            }
        };
    }, [data]);
    return (
        <Dialog
            fullWidth={true}
            maxWidth={'md'}
            open={true}
            onClose={handleClose}
        >
            <DialogTitle>{data.description}</DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                   
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>

                <video autoPlay controls style={{ height: '480px' }} src={blobUrl}></video>
            </DialogContent>
        </Dialog>
    )
}

export default VideoPlayBox