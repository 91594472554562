import React from 'react'
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';

function SidebarLayout() {
  return (
    <>    
      <Header/>
      <Sidebar/>
      <Outlet />
      <Footer/>
    </>
  )
}

export default SidebarLayout