import React from 'react'
import { Outlet } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import UserSideBar from './UserSideBar';

function UserLayout() {
  return (
    <>    
      <Header/>
      <UserSideBar/>
      <Outlet />
      <Footer/>
    </>
  )
}

export default UserLayout